.msg-erro
  max-width 100%
  position absolute
  top -45px
  left 15px
  color #fff
  z-index 100
  background #6e2120
  padding 10px
  border-radius 5px
  text-align justify
  line-height 25px
  cursor pointer
  &.hide
    visibility hidden

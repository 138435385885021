.-images
  .col
    width 50%
    @media(max-width 768px)
      width 50%
    img
      width 100%
      @media(max-width 414px)
        margin 3px 0
    @media(max-width 414px)
      width 100%
      padding 0

.page
  border-top 5px solid #dfc671
  padding-top 72px
  background url('../img/page.png') no-repeat
  background-position top center
  
  // Texto default de todas as paginas
  .text
    color #404040
    font-size 18px
    font-weight 300
    line-height 30px
    text-align justify
    margin 56px 0
    p
      margin 16px 0

// Titulo default de todas as paginas
.the_title
  font-family 'Cinzel'
  color  #404040
  font-size 35px
  font-weight 700
  line-height 30.756px
  text-align center
  position relative
  height 64px
  letter-spacing 10px
  &:before
    position absolute
    content ''
    size(322px, 2px)
    bottom 0
    left 50%
    margin-left -161px
    background-image linear-gradient(to right, rgba(0, 0, 0, 0), #dfc671, rgba(0, 0, 0, 0))
  &.-home
    font-weight 400
    &:before
      display none

.breadcrumb
  text-align center
  width 100%
  margin 20px 0 50px 0
  li
    display inline-block
    text-transform uppercase
    color #c7af5a
    font-size 13px
    font-weight 400
    &:before
      display none
    &.divider
      color #404040

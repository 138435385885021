.-contato
  position relative
  @media(max-width 768px)
    flex-direction column-reverse
  .col
    width 50%
    @media(max-width 768px)
      width 100%
    .box.-info
      background #ededed
      margin 10px 0
      padding 27px 30px
      width 100%
      span
        display block
        color #807657
        font-size 16px
        line-height 24px
        &.title
          text-transform uppercase
      .icon-default
        color #e0d557
        font-size 50px
        float left
        margin-right 30px
        height 100%
        padding 13px 0

      .icons
        margin-top 12px
        a
          size(34px, 34px)
          display inline-block
          color #6e2120
          border 2px solid #cebd8d
          border-radius 50%
          text-align center
          padding 7px 0
          @media(max-width 768px)
            padding 4px 0

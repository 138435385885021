.post
  .col
    width 100%
  .title
    font-family 'Cinzel'
    color #404040
    font-size 30px
    font-weight 700
    line-height 30px
    text-align left
  .tag
    display block
    text-transform uppercase
    color #00df7e
    font-size 18px
    border-top 4px solid #00df7e
    padding-top 15px
    margin-top 23px
    width 200px

  .date
    display block
    size(161px, 50px)
    border 2px solid #a19f9a
    color #00df7e
    text-transform uppercase
    text-align center
    font-size 18px
    padding-top 10px
    margin-bottom 50px

  .thumbnail
    width 100%
    margin 60px 0

  .subtitle
    color #00df7e
    font-family 'Cinzel'
    margin 40px 0

  iframe
    width 853px
    height 480px
    margin 60px auto
    display block
    @media(max-width 853px)
      width 100%
      min-height 400px


.header-post
  position relative
  .share
    position absolute
    top 0
    right 0
    margin 0
    @media(max-width 414px)
      position relative
      margin-bottom 20px
      ul
        text-align left
        li
          text-align center

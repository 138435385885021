.-products
  margin-bottom 80px

  .cols
    align-content flex-start
    justify-content flex-start

  .tab-content
    display none
    &.-active
      display block

  .col
    width 25%
    @media(max-width 768px)
      width 50%
    @media(max-width 414px)
      width 100%

  .box.-product
    width 100%
    margin 50px 0
    cursor pointer
    font-size 13px
    text-transform uppercase
    line-height 22px
    color #404040
    &:hover
      &.-mal .thumbnail
        border-color #00df7e
      &.-fem .thumbnail
        border-color #de8789
      &.-pro .thumbnail
        border-color #ead141

      .button-icon
        visibility visible

    .thumbnail
      position relative
      width 100%
      padding 24px 12px
      border 3px solid transparent
      margin-bottom 10px
      img
        width 100%

    .title
      font-weight 400

    .button-icon
      cursor pointer
      size(45px, 45px)
      text-align center
      color #fff
      border none
      position absolute
      top 50%
      left 50%
      margin-top -22.5px
      margin-left -22.5px
      visibility hidden

    &.-mal
      .title
        color #00df7e
      .thumbnail
        border-bottom 3px solid #00df7e
      .button-icon
        background #00df7e

    &.-fem
      .title
        color #de8789
      .thumbnail
        border-bottom 3px solid #de8789
      .button-icon
        background #de8789

    &.-pro
      .title
        color #ead141
      .thumbnail
        border-bottom 3px solid #ead141
      .button-icon
        background #ead141

.-loadmore
  .col
    width 100%

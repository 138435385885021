bp = {
  mobile: 414px,
  tablet: 768px,
  desktop: 1050px
}

max-width = 1200px

path = {
  assets: '../assets/'
}

color = {
  green: green,
  gray: #666,
  light-gray: #ccc,
  dark-gray: #333,
  dark-blue: #01092a
}

font = {
  default: {
    name: 'Roboto',
    weight: normal
  }
}

circle()
  border-radius 50%

size(w, h)
  width w
  height h

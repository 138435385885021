.tabela-medidas
  margin-top 60px
  .col
    width 25%
    @media (max-width 768px)
      width 100%

    .box
      size(100%, 120px)
      text-align center
      text-transform uppercase
      padding 30px 39px 20px 39px
      color #fdfdfc
      background-size cover
      background-position top center
      background-repeat no-repeat
      @media(max-width 768px)
       margin 10px 0

      .title
        font-size 13px
        width 100%
        display block
        padding-bottom 9px

      .content
        font-family 'Cinzel'
        font-weight 700
        display block
        padding-top 5px

      &.-fem.-one
        background-color #de8789
        .title
          border-bottom 2px solid rgba(255, 255, 255, .3)
      &.-fem.-two
        background-color #ff9398
        .title
          border-bottom 2px solid rgba(0, 0, 0, .1)
      &.-mal.-one
        background-color #00df7e
        .title
          border-bottom 2px solid rgba(255, 255, 255, .3)
      &.-mal.-two
        background-color #00ee89
        .title
          border-bottom 2px solid rgba(0, 0, 0, .1)

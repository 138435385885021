.share
  text-align center
  margin 70px 0
  .title
    color #808080
    font-size 13px
    font-weight 300
    line-height 24px
    text-transform uppercase
    margin-bottom 20px
  li
    display inline-block
    size(45px, 45px)
    border-radius 50%
    margin 0 4px
    a
      display table
      size(100%, 100%)
      i
        display table-cell
        vertical-align middle
        position relative

    &:nth-child(1)
      border 2.5px solid #3c589e
    &:nth-child(2)
      border 2.5px solid #1da1f2
    &:nth-child(3)
      border 2.5px solid #007bb6
    &:nth-child(4)
      border 2.5px solid #6df100

    &:before
      display none

html
  min-height 100%
  position relative
body
  height 100%

.menu.-primary
  visibility hidden
  &.-active
    visibility visible
    .menu-background
      background rgba(14, 14, 14, .9)
      transition ease .4s
      @media(max-width 768px)
        width 50%
      @media(max-width 414px)
        width 100%
    .col
      ul
        top 190px
        transition ease .8s

  .menu-background
    background-color transparent
    position absolute
    top 0
    bottom 0
    right 0
    overflow hidden
    z-index 100
    width 40%
    transition ease .4s

  .col
    width 100%
    position relative
    ul
      text-align right
      position absolute
      z-index 200
      right 15px
      top 0
      transition ease .1s

      li
        color #fdfdfc
        font-family 'Cinzel'
        font-size 24px
        margin 15px 0
        text-transform uppercase
        &:before
          display none
        &.is-active
          color #cebd8d
          font-weight 700
        &:hover
          color #cebd8d

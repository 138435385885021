#slider-depoimentos-nav
  text-align center
  li
    display inline-block
    &:before
      display none
    button
      size(15px, 15px)
      cursor pointer
      border 2px solid #fff
      border-radius 50%
      position relative
      text-indent -99999px
      background transparent
      margin 0 5px

    &.slick-active
      button
        background #ffe9a9
        border-color #ffe9a9

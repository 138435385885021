.-clients
  .col
    width 20%
    margin 10px 0
    @media(max-width 768px)
      width 33.333%
    @media(max-width 414px)
      width 50%
  .box.-client
    background #ededed
    padding 26px
    img
      max-width 100%
      margin 0 auto
      display block

*,
*:before,
*:after
  box-sizing border-box
  margin 0
  padding 0

*:focus
  outline none

a
  color inherit
  text-decoration none

strong
  font-weight bold

html
  font-family 'Roboto'
  overflow-x hidden
  body
    overflow-x hidden

::selection
  background rgba(223, 198, 113, .9)
  color #fff

.wrapper.-products
  background-color #f1efe8
  padding 40px 0 80px 0
  margin-bottom 0 !important
  .col
    width 33.333%
    @media(max-width 768px)
      width 100%
      margin 20px 0
    .box
      &:hover
        &.-mal, &.-fem, &.-pro
          .button
            color #fff
            transition ease .3s
        &.-mal
          .button
            background #00df7e
        &.-fem
          .button
            background #de8789
        &.-pro
          .button
            background #ead141


      .thumbnail
        size(100%, auto)
        margin-bottom 30px
      .title
        font-family 'Cinzel'
        color #404040
        font-size 22px
        font-weight 700
        line-height 30.756px
        text-align center
        position relative
        padding-bottom 19px
        &:before
          content ''
          size(100px, 4px)
          position absolute
          bottom 0
          left 50%
          margin-left -50px
      .button
        size(160px, 50px)
        margin 60px auto 0 auto
        transition ease .3s

      &.-mal
        .title
          &:before
            background-color #00df7e
        .button
          color #00df7e
          &.-bordered
            border-color #00df7e

      &.-fem
        .title
          &:before
            background-color #de8789
        .button
          color #de8789
          &.-bordered
            border-color #de8789

      &.-pro
        .title
          &:before
            background-color #ead141
        .button
          color #ead141
          &.-bordered
            border-color #ead141

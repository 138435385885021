.button
  cursor pointer
  border none
  text-align center
  display block
  &.-full
    size(100%, 50px)
  &.-default
    background #72cf00
    color #fdfdfc
    text-transform uppercase
    font-size 18px
    font-weight 700
  &.-bordered
    background transparent
    border 2px solid #a19f9a
    color #c6c6c6
    font-size 22px

  &.-oliver
    font-family 'Cinzel'

  &.-link
    padding-top 10px

.left
  float left

.right
  float right

.wrapper
  clearfix()

.container
  center(max-width, $pad: 10px)

.clearfix
  clearfix()

li
  list-style none
  &:before
    content "• "
    font-weight bold
  &.no-style
    &:before
      display none

.pointer
  cursor pointer

.hidden-mobile
  @media (max-width 768px)
    display none !important

.hidden-desktop
  @media(min-width 769px)
    display none

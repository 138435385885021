.tabs
  .col.-tab
    width 25%
    .button-tab
      display block
      cursor pointer
      position relative
      size(100%, 60px)
      background none
      border 2px solid transparent
      font-family 'Cinzel'
      color #404040
      font-size 20px
      text-align center
      font-weight 700
      z-index 10
      padding-top 15px
      &:before
        content ''
        position absolute
        size(100px, 4px)
        bottom -3px
        left 50%
        margin-left -50px
        z-index 5
      &:after
        content ''
        position absolute
        size(140px, 4px)
        bottom -3px
        left 50%
        margin-left -70px
        background #fff
        z-index 1

      &.-all
        &:before
          background #9e9e9e
      &.-mal
        &:before
          background #00df7e
      &.-fem
        &:before
          background #de8789
      &.-pro
        &:before
          background #ead141

      &:hover, &.-active
        &.-all
          border-color #9e9e9e
        &.-mal
          border-color #00df7e
        &.-fem
          border-color #de8789
        &.-pro
          border-color #ead141

.tabs-select
  width 100%
  cursor pointer
  background none
  border 2px solid transparent
  padding 10px
  font-family 'Cinzel'
  &.-all
    border-color #9e9e9e
  &.-mal
    border-color #00df7e
  &.-fem
    border-color #de8789
  &.-pro
    border-color #ead141

.tabs-mobile
  padding 0 10px

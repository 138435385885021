@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?gdk41a');
  src:  url('../fonts/icomoon/icomoon.eot?gdk41a#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?gdk41a') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?gdk41a') format('woff'),
    url('../fonts/icomoon/icomoon.svg?gdk41a#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: "\e900";
}
.icon-close:before {
  content: "\e901";
}
.icon-envelope:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e903";
}
.icon-google:before {
  content: "\e904";
}
.icon-instagram:before {
  content: "\e905";
}
.icon-linkedin:before {
  content: "\e906";
}
.icon-hamburger:before {
  content: "\e907";
}
.icon-pin:before {
  content: "\e908";
}
.icon-user:before {
  content: "\e909";
}
.icon-search:before {
  content: "\e90a";
}
.icon-share:before {
  content: "\e90b";
}
.icon-speech:before {
  content: "\e90c";
}
.icon-phone:before {
  content: "\e90d";
}
.icon-twitter:before {
  content: "\e90e";
}
.icon-whatsapp:before {
  content: "\e90f";
}

.wrapper.-sliderheader
  background #f1efe8 url('../img/fundo-slider.png') no-repeat
  background-position top center
  background-size cover
  padding 0 15px 70px 15px
  .col
    width 100%
    overflow hidden
  #sliderheader
    position relative
    overflow hidden
    max-height 520px
    @media(max-width 768px)
      max-height 240px
    .box.-slider
      width 50%
      float left
      position relative
      padding 0 10px
      @media(max-width 768px)
        width 100%
      .item
        width 100%
        padding 0 20px

// arrow buttons
.slick-arrow
  position absolute
  background #fff
  border none
  cursor pointer
  display table
  size(50px, 50px)
  top 50%
  margin-top -25px
  z-index 10
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size 18px
  text-align center
  box-shadow 1px 0px 10px rgba(0, 0, 0, .3)

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover
    &:before
      color #6e2120

  &:before
    color #cebd8d

  &.prevArrow
    left 10px
    transform rotate(-180deg)
    &:before
      content: "\e900";
  &.nextArrow
    right 10px
    &:before
      content: "\e900";

#footer
  background-color #ededed
  padding 99px 0
  position relative
  .border
    position absolute
    top 0
    left 0
    size(100%, 5px)
    background url('../img/borda-footer.png') no-repeat
    background-size cover
    background-position top center

  .col
    @media(max-width 768px)
      width 100%
      position relative

  .box.-footer
    @media(max-width 768px)
      margin 10px 0
    .icon-default
      color #e0d557
      font-size 50px
      float left
      margin-right 25px
      height 100%
      width 15%
      padding 13px 0
      @media(max-width 768px)
        margin-right 20px
        padding 0
        width auto
    .text
      width 85%
    span
      color #807657
      font-size 14px
      font-weight 300
      line-height 23px
      &.title
        text-transform uppercase
        display block
        &.-social
          text-align center
          @media(max-width 768px)
            text-align left
            margin-top 20px
    ul
      margin-top 12px
      li
        display inline-block
        size(34px, 34px)
        border-radius 50%
        margin 0
        text-align center
        border 2px solid #cebd8d
        color #6e2120
        a
          display table
          size(100%, 100%)
          i
            display table-cell
            vertical-align middle
        &:before
          display none

#header
  &.-home
    background url('../img/header-home.png') no-repeat
    background-position top center
    background-size cover
  .container
    position relative
  .col
    width 100%
  .logo
    width 100%
    display block
    img
      display block
      max-width 322px
      max-height 147px
      margin 30px auto
      @media(max-width 510px)
        margin 30px 0
        max-width 252px
      @media(max-width 414px)
        margin 30px 0
        max-width 222px

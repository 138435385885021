.button-toggle-menu
  display block
  width 60px
  height 60px
  border-radius 50%
  padding 0
  border 2px solid #cebd8d
  background #fff
  outline none
  cursor pointer
  position absolute
  right 25px
  top 50%
  margin-top -30px
  z-index 200
  .hamburger
    position absolute
    width 30px
    height 20px
    top 50%
    left 50%
    margin-top -10px
    margin-left -15px
    .burger
      background #6e2120
      width 100%
      height 4px
      position absolute
      left 0
      border-radius 5px
      transition .2s
      &:nth-child(1)
        top 0
      &:nth-child(2)
        top 50%
        margin-top: -2px;
      &:nth-child(3)
        top 100%
        margin-top -4px

  &.is-active
    background #6e2120
    .hamburger
      .burger
        background #fdfdfc
        transition .2s
        &:nth-child(1)
          transform rotate(-45deg)
          top 50%
          margin-top -2px
        &:nth-child(2)
          opacity 0
        &:nth-child(3)
          transform rotate(45deg)
          top 50%
          margin-top -2px

.-listblog
  margin-bottom 80px
  .col
    width 100%

    &:nth-child(odd)
      .box.-post
        .thumbnail
          float left
        .content
          float right
          .tag
            text-align left
            padding-left 99px
            left -59px
            @media(max-width 768px)
              left 0
              padding-left 0
          .title
            padding-left 40px
            text-align left
            @media(max-width 768px)
              padding-left 0

    &:nth-child(even)
      .box.-post
        .thumbnail
          float right
          @medi(max-width 768px)
            float none
        .content
          float left
          @medi(max-width 768px)
            float none
          .tag
            text-align right
            padding-right 99px
            right -59px
            @media(max-width 768px)
              left 0
              padding-right 0
              text-align left
          .title
            padding-right 40px
            text-align right
            @media(max-width 768px)
              text-align left


    .box.-post
      width 100%
      margin 40px 0

      .title
        font-family 'Cinzel'
        color #404040
        font-size 22px
        font-weight 700
        line-height 30.756px
        margin-top 60px
        padding-bottom 66px
        display block
        min-height 53px
      .tag
        display block
        font-size 18px
        padding-top 20px
        width 200px
        text-align left
        border-top 2px solid transparent
        text-transform uppercase
        position absolute
        bottom 0

        &.-dicas
          color #00df7e
          border-color #00df7e
        &.-moda
          color #de8789
          border-color #de8789

      .content
        position relative
        size(50%, auto)
        @media(max-width 768px)
          width 100%

      .thumbnail
        size(50%, auto)
        @media(max-width 768px)
          width 100%

.-moreblog
  margin-bottom 75px
  justify-content center !important
  .button
    width 334px
    height 50px
    margin 0 auto
    transition ease .3s
    &:hover
      background #a19f9a
      color #fff
      transition ease .3s

.wrapper.-background
  background url('../img/fundo-depoimentos.png') no-repeat
  background-size cover
  background-position top center
  padding 63px 0
  margin-bottom 80px
  .col
    width 100%
    .box.-depo
      width 100%
      background-color #d1c889
      padding 35px 40px
      clear both
      @media(max-width 900px)
        padding 35px 10px
      #slider-depoimentos
        height auto
        overflow hidden
      .slider-container
        display flex
        width 100%
        float left
        padding 0 50px
        @media(max-width 768px)
          flex-flow row wrap
          padding 0
      .thumbnail
        width 20%
        @media(max-width 700px)
          width 100%
          text-align center
        img
          size(180px, 180px)
          border-radius 50%
          border 3px solid rgba(0, 0, 0, .2)
          @media(max-width 900px)
            size(160px, 160px)
      .content
        width 80%
        color #fff
        font-size 17px
        line-height 22px
        text-align justify
        font-weight 300
        position relative
        @media(max-width 700px)
          width 100%
        .text
          padding 0 60px
          margin-top 9px
        &:before, &:after
          font-family 'Cinzel'
          color #807e53
          font-size 140px
          font-weight 400
          position absolute
          line-height 22px
        &:before
          content '”'
          bottom 16px
          right 0
          @media(max-width 700px)
            bottom 0
        &:after
          content '“'
          top 56px
          left 0
          @media(max-width 700px)
            top 40px
        .name
          font-family 'Cinzel'
          color #807657
          font-size 18px
          font-weight 700
          text-align right
          width 100%
          display block
          margin-top 23px
          padding-right 60px

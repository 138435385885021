.wrapper.-minform
  background url('../img/minform-bg.png') no-repeat
  padding 77px 0 80px 0
  margin 70px 0
  background-position top center
  background-size cover

  .title
    font-family 'Cinzel'
    color #fdfdfc
    font-size 24px
    font-weight 700
    line-height 30px
    text-align center
    position relative
    height 46px
    margin-bottom 50px
    letter-spacing 10px
    &:before
      position absolute
      content ''
      size(322px, 2px)
      bottom 0
      left 50%
      margin-left -161px
      background-image linear-gradient(to right, rgba(0, 0, 0, 0), #404040, rgba(0, 0, 0, 0))
      @media(max-width 768px)
        display none

  .cols
    max-width 630px
    margin 0 auto
    .col
      width 100%
      .input
        background transparent
        border-color #fff

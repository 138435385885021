.wrapper.-related
  margin 80px 0
  .col
    width 50%
    @media(max-width 768px)
      width 100%

    &.-nav
      @media(max-width 768px)
        width 50%
      @media(max-width 414px)
        width 100%
        .navigation
          text-align left


  .box.-post
    margin-top 30px

    .thumbnail
      size(100%, auto)

    .title
      font-family 'Cinzel'
      color #404040
      font-size 22px
      font-weight 700
      line-height 30px
      margin 25px 0

    .tag
      display block
      text-transform uppercase
      color #00df7e
      font-size 18px
      border-top 4px solid #00df7e
      padding-top 15px
      margin-top 23px
      width 200px

  .navigation
    color #807657
    font-size 24px
    font-weight 400
    width 100%
    display block
    text-transform uppercase
    margin-top 60px
    &.-next
      text-align right
    &.-prev
      text-align left

.menu.-social
  position absolute
  top 50%
  left 25px
  margin-top -23px
  height 46px
  li
    display inline-block
    size(45px, 45px)
    border-radius 50%
    margin 0 4px
    text-align center
    border 2px solid #cebd8d
    color #6e2120
    a
      display table
      size(100%, 100%)
      transition ease .3s
      &:hover
        opacity .5
        transition ease .3s
      i
        display table-cell
        vertical-align middle
        position relative
    &:before
      display none

.modal
  size(100%, 100%)
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  z-index 1000
  display none
  &.-active
    display block
  .modal-background
    size(100%, 100%)
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0, 0, 0, .9)

  .modal-body
    position relative
    z-index 2000
    width 100%
    text-align center
    padding 10px 15px
    display flex
    align-items center
    justify-content center
    height 100%

    img
      max-width 100%

  .button-close-modal
    z-index 3000
    position absolute
    top 50px
    right 50px
    background none
    border none
    color #fff
    font-size 30px
    cursor pointer

.open-modal
  cursor pointer

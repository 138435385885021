.input-group
  position relative
  width 100%
  margin 10px 0
  .input, .textarea
    size(100%, 60px)
    padding-left 20px
    color #404040
    font-size 16px
    border 2px solid #cfcdc7
    &::placeholder
      color #404040
    &.-error
      border-color #6e2120
      &::placeholder
        color #6e2120

  .textarea
    padding-top 23px
    size(100%, 118px)

  .icon
    color #dfc671
    font-size 22px
    position absolute
    top 15px
    right 20px
